import React, { Component } from "react";

import { TextField, Button, FormControl } from "@material-ui/core";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";
import Autocomplete from "@material-ui/lab/Autocomplete";
const axios = require("axios");

export class AddNewEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routesData: [],

      vehicleId: this.props.vehicleId,
      routeId: null,
      date: null,
      startReading: 0,
      endReading: 0,
      totalReading: 0,
      totalCost: 0,
      paid: 0,
      pending: 0,
      diesel: "",
    };
  }

  getCurrentBalance() {}

  fetchRoutesData() {
    let url = API_URL;
    const query = `SELECT * FROM routes;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("route data: ", res.data);
        this.setState({ routesData: res.data });
      })
      .catch((err) => {
        console.log("route data error: ", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;
    let pending = this.state.totalCost - this.state.paid;

    const query = `INSERT INTO vehicleLedger(vehicleId, routeId, startReading, endReading, totalReading, diesel, totalCost, paid, pending) VALUES(${this.state.vehicleId}, '${this.state.routeId}', ${this.state.startReading}, ${this.state.endReading}, ${this.state.totalReading}, ${this.state.diesel}, ${this.state.totalCost}, ${this.state.paid}, ${pending});`;

    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        console.log("Vehicle ledger record added successfully");
        toast.success("record added successfully");
        this.props.refreshLedger();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.fetchRoutesData();
    this.getCurrentBalance();
  }

  render() {
    return (
      <div className="row">
        <form autoComplete="off">
          <div className="row ml-4 mt-4">
            <FormControl
              // style={{ minWidth: "250px" }}
              className="mr-2 mb-2 smt-0"
            >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={
                  this.state.routesData != null
                    ? this.state.routesData.map(
                        (item) => item.id + "." + item.name
                      )
                    : []
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Route"
                    variant="outlined"
                    size="small"
                    style={{ width: 200 }}
                  />
                )}
                onChange={(event, value) => {
                  this.setState({ routeId: value.split(".")[0] });
                }}
              />
            </FormControl>

            <TextField
              id="startReading"
              label="Start Reading"
              variant="outlined"
              type="number"
              className="mr-2"
              size="small"
              style={{ width: 150 }}
              onChange={(e) => this.setState({ startReading: e.target.value })}
            />
            <TextField
              id="endReading"
              label="End Reading"
              variant="outlined"
              className="mr-2"
              type="number"
              size="small"
              style={{ width: 150 }}
              onChange={(e) => this.setState({ endReading: e.target.value })}
            />

            <TextField
              id="totalReading"
              label="total Reading"
              variant="outlined"
              className="mr-2"
              type="number"
              size="small"
              style={{ width: 150 }}
              onChange={(e) => this.setState({ totalReading: e.target.value })}
            />

            <TextField
              id="totalCost"
              label="total Cost"
              variant="outlined"
              className="mr-2"
              type="number"
              size="small"
              style={{ width: 100 }}
              onChange={(e) => this.setState({ totalCost: e.target.value })}
            />

            <TextField
              id="paid"
              label="Paid"
              variant="outlined"
              className="mr-2"
              type="number"
              size="small"
              style={{ width: 100 }}
              onChange={(e) => this.setState({ paid: e.target.value })}
            />

            <TextField
              id="diesel"
              label="Diesel"
              variant="outlined"
              className="mr-2"
              type="number"
              size="small"
              style={{ width: 100 }}
              onChange={(e) => this.setState({ diesel: e.target.value })}
            />

            <Button
              color="primary"
              variant="contained"
              className=""
              onClick={(e) => this.handleAddSubmit(e)}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="2x" />
            </Button>
            <Button
              color="secondary"
              variant="contained"
              className="ml-2"
              onClick={this.props.refreshLedger}
            >
              <FontAwesomeIcon icon={faSyncAlt} size="2x" />
            </Button>
          </div>
        </form>
        <ToastContainer />
      </div>
    );
  }
}
