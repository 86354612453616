import React, { Component } from "react";

//Bootstrap and jQuery libraries
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

// styles
import "./style.css";
import "bootstrap/dist/css/bootstrap.css";

// material UI imports
import {
  TableBody,
  TableContainer,
  Button,
  Paper,
  TextField,
  CircularProgress,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

// Toastify imports
import { toast } from "react-toastify";

// import child components
import { AddNewEntry } from "./AddNewEntry.js";

//API handling components
import { API_URL } from "./../../global";
const axios = require("axios");

export default class VehicleLedgerManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleId: this.props.match.params.vehicleId,
      showAddModal: false,
      showUpdateModel: false,
      //   activeRecordId: null,
      //   activeVehicleId: null,
      //   activeParticular: null,
      //   activeDebit: null,
      //   activeCredit: null,
      //   activeBalance: null,
      //   vehicleData: null,
      vehicleLedgerData: null,
      activeVehicleLedgerId: "",
      activePaid: "",
      activePending: "",

      startReading: "",
      endReading: "",
      totalReading: "",
      totalCost: "",
      paid: "",
      pending: "",
      //   totalBalance: 0,

      totalPending: 0,

      isLoadingVehicleLedger: false,
    };
  }

  fetchBalance() {
    // if party id is null
    if (!this.state.vehicleId) return null;

    let url = API_URL;
    const query = `SELECT (SUM(total) + SUM(debit) - SUM(credit)) as balance FROM ledger where party_id=${this.state.vehicleId};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("party balance: ", res.data[0]["balance"]);
        this.setState({ totalBalance: res.data[0]["balance"] });
      })
      .catch((err) => {
        console.log("party data fetch error: ", err);
      });
  }

  fetchVehicleData() {
    // if party id is null
    if (!this.state.vehicleId) return null;

    let url = API_URL;
    const query = `SELECT * FROM vehicles WHERE id=${this.state.vehicleId};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("vehicle data: ", res.data);
        this.setState({ vehicleData: res.data });
      })
      .catch((err) => {
        console.log("vehicle data fetch error: ", err);
      });
  }

  fetchvehicleLedgerData = () => {
    // if party id is null
    if (!this.state.vehicleId) return null;

    let url = API_URL;
    const query = `SELECT r.name as routeName, vl.* FROM vehicleLedger vl inner join routes r WHERE vehicleId=${this.state.vehicleId} AND r.id = vl.routeId;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    this.setState({ isLoadingVehicleLedger: true });
    axios
      .post(url, data)
      .then((res) => {
        console.log("vehicle ledger data: ", res.data);
        this.setState(
          {
            vehicleLedgerData: res.data,
            isLoadingVehicleLedger: false,
          },
          this.calculateTotalPending
        );
        // this.initializeDataTable();
      })
      .catch((err) => {
        console.log("ledger data fetch error: ", err);
        this.setState({ isLoadingVehicleLedger: false });
      });
  };

  handleUpdateSubmit(e) {
    let url = API_URL;
    const query = `UPDATE vehicleLedger SET pending=${
      +this.state.activePending - +this.state.activePaid
    } WHERE id=${this.state.activeVehicleLedgerId};`;

    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("ledger details updated successfully");
        this.fetchvehicleLedgerData();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  calculateTotalPending() {
    if (!this.state.vehicleLedgerData) return;
    let totalPending = this.state.vehicleLedgerData.reduce(
      (total, record) => (total += record.pending),
      0
    );
    this.setState({ totalPending });
  }

  refreshLedger() {
    window.location.reload(false);
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `DELETE FROM vehicleLedger WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted status data: ", res.data);
        toast.error("Record deleted successfully");
        this.fetchvehicleLedgerData();
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  componentDidMount() {
    this.fetchVehicleData();
    this.fetchvehicleLedgerData();
    this.fetchBalance();
  }

  componentDidUpdate() {
    const title =
      `${this.state.vehicleData?.[0]["vehicleNo"]}-ledger-` +
      moment().format("DD/MM/YYYY");
    $("#ledger_table").DataTable({
      destroy: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      buttons: [
        {
          extend: "csv",
          title,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          extend: "print",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
      ],
    });
  }

  renderVehicleData = () => {
    const vehicle = this.state.vehicleData;
    if (!vehicle) return null;

    return (
      <div className="mb-2">
        <h5 className="mt-2 d-flex justify-content-between">
          <Box>
            {vehicle[0]["id"]} | <b>{vehicle[0]["vehicleNo"]}</b>
          </Box>
          <Box px={2}>
            Total pending amount:<b> {this.state.totalPending}</b>
          </Box>
        </h5>
      </div>
    );
  };

  renderUpdateModal = () => {
    return (
      <Modal
        show={this.state.showUpdateModel}
        onHide={(e) => this.setState({ showUpdateModel: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update record
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col xs={12}>
                  <TextField
                    id="pending"
                    label="Paid"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    value={this.state.activePaid}
                    onChange={(e) =>
                      this.setState({
                        activePaid: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>

            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModel: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  };

  rendervehicleLedgerData = () => {
    if (this.state.vehicleLedgerData == null) {
      return null;
    }

    const ledger = this.state.vehicleLedgerData;
    let last_modified = null;
    let balance = 0;

    return ledger.map((record) => {
      // extract date only
      last_modified = moment(record["last_modified"]).format(
        "DD/MM/YYYY HH:MM"
      );

      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{record["id"]}</Badge>{" "}
          </td>
          {/* <td align="center">{record["routeId"]}</td> */}
          <td align="center">{record["routeName"]}</td>
          <td>{record["startReading"]}</td>
          <td>{record["endReading"]}</td>
          <td>{record["totalReading"]}</td>
          <td>{record["diesel"]}</td>
          <td>{record["totalCost"]}</td>
          <td>{record["paid"]}</td>
          <td>{record["pending"]}</td>
          <td>{last_modified}</td>
          <td>
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  activeVehicleLedgerId: record.id,
                  activePending: record.pending,
                  showUpdateModel: true,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </Button>
            <Button
              // color="secondary"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(record.id);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  render() {
    return (
      <div className="container-fluid border m-0 p-1">
        {this.renderVehicleData()}
        <br />
        <hr />
        <div class="btn-group mb-3" role="group" aria-label="Basic example">
          <AddNewEntry
            vehicleId={this.state.vehicleId}
            // refreshLedger={() => this.refreshLedger()}
            refreshLedger={() => this.fetchvehicleLedgerData()}
            totalBalance={this.state.totalBalance}
          />
        </div>

        <Row className="ml-0 mr-0">
          <Col md="12" className="p-0 m-0 measure1">
            {this.state.isLoadingVehicleLedger && (
              <Box
                width="100%"
                height="100px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <CircularProgress />
              </Box>
            )}
            {!this.state.isLoadingVehicleLedger && (
              <TableContainer component={Paper} style={{ maxHeight: "79vh" }}>
                <table
                  id="ledger_table"
                  class="display"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th align="center">ID</th>
                      <th align="center">route Name</th>
                      <th>strart reading</th>
                      <th>end reading</th>
                      <th>total reading</th>
                      <th>Diesel</th>
                      <th>total cost</th>
                      <th>paid </th>
                      <th>pending</th>
                      <th>last modified</th>
                      <th align="center">Options</th>
                    </tr>
                  </thead>
                  <TableBody>{this.rendervehicleLedgerData()}</TableBody>
                </table>
              </TableContainer>
            )}
            {this.renderUpdateModal()}
          </Col>
        </Row>
      </div>
    );
  }
}
