import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

// Toastify imports
import { ToastContainer, toast } from "react-toastify";
// import "../ledger_manager/exportManager/node_modules/react-toastify/dist/ReactToastify.css";

//API handling components
import { API_URL } from "./../../global";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

export default class VehicleManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      showUpdateModel: false,
      // value: "1",
      // activePartyId: "",
      // activePartyName: "",
      // activePartyMobile: "",
      // activePartyAddress: "",
      // activePartyType: 1,
      activeVehicleId: "",
      activeVehicleNo: "",
      activeOwnerName: "",
      activeMobile: "",

      vehicleNo: "",
      ownerName: "",
      mobile: "",
      vehiclesData: null,

      isLoadingVehicles: false,
    };
  }

  fetchVehiclesData() {
    let url = API_URL;
    const query = `SELECT * FROM vehicles WHERE status=1;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    this.setState({ isLoadingVehicles: true });
    axios
      .post(url, data)
      .then((res) => {
        console.log("vehicle data: ", res.data);
        this.setState({ vehiclesData: res.data, isLoadingVehicles: false });
      })
      .catch((err) => {
        console.log("vehicle data error: ", err);
        this.setState({ isLoadingVehicles: false });
      });
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `UPDATE vehicles SET vehicleNo="${this.state.activeVehicleNo}", ownerName="${this.state.activeOwnerName}", mobile="${this.state.activeMobile}" WHERE id=${this.state.activeVehicleId};`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("Vehicle details updated successfully");
        this.fetchVehiclesData();
      })
      .catch((err) => {
        console.log("error while updating vehicles data", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `INSERT INTO vehicles(vehicleNo, ownerName, mobile) VALUES('${this.state.vehicleNo}', '${this.state.ownerName}', '${this.state.mobile}')`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        toast.success("Vehicle details added successfully");
        this.fetchVehiclesData();
        // setTimeout(() => {
        //   this.refreshVehicles();
        // }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `UPDATE vehicles SET status = 0  WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted status data: ", res.data);
        console.log("Party deleted successfully");
        toast.error("Party deleted successfully");
        this.fetchVehiclesData();
        // setTimeout(() => {
        //   this.refreshVehicles();
        // }, 2000);
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  refreshVehicles() {
    window.location.reload(false);
  }

  componentDidMount() {
    this.fetchVehiclesData();
  }

  componentDidUpdate() {
    const title = "Vehicle list";
    $("#vehicles_table").DataTable({
      destroy: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      buttons: [
        {
          extend: "csv",
          title,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
        {
          extend: "print",
          title,
          messageTop: `<h4 style='text-align:center'>${title}</h4>`,
          download: "open",
          exportOptions: {
            columns: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
          },
        },
      ],
    });
  }

  renderVehiclesData = () => {
    const vehicles = this.state.vehiclesData;

    if (vehicles == null) {
      return null;
    }

    return vehicles.map((vehicle) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{vehicle["id"]}</Badge>{" "}
          </td>
          <td align="center">{vehicle["vehicleNo"]}</td>
          <td align="center">{vehicle["ownerName"]}</td>
          <td align="center">
            <a href={"tel:" + vehicle["vehicleNo"]}>
              <Button className="mx-1" color="primary" variant="secondary">
                {vehicle["mobile"]}
              </Button>
            </a>
          </td>

          <td align="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  showUpdateModal: true,
                  activeVehicleId: vehicle.id,
                  activeVehicleNo: vehicle.vehicleNo,
                  activeOwnerName: vehicle.ownerName,
                  activeMobile: vehicle.mobile,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </Button>
            <Link to={`vehicleLedgerManager/${vehicle["id"]}`}>
              <Button
                className="mx-1"
                color="primary"
                variant="contained"
                onClick={(e) => {}}
              >
                <FontAwesomeIcon icon={faBook} />
              </Button>
            </Link>
            <Button
              className="mx-1"
              color="danger"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(vehicle["id"]);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  renderNewPartyModal() {
    return (
      <Modal
        show={this.state.showAddModal}
        onHide={(e) => this.setState({ showAddModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3 mr-3">
              <Row>
                <Col>
                  <TextField
                    id="ownerName"
                    label="Owner name"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    onChange={(e) => {
                      this.setState({
                        ownerName: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="vehicleNo"
                    label="Vehicle no"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    onChange={(e) =>
                      this.setState({
                        vehicleNo: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Col>
                <Col>
                  <TextField
                    id="mobile"
                    label="Mobile"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    onChange={(e) =>
                      this.setState({
                        mobile: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showAddModal: false,
                  });
                  this.handleAddSubmit(e);
                }}
              >
                Add
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  renderUpdatePartyModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Party
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3 mr-3">
              <Row>
                <Col>
                  <TextField
                    id="activeOwnerName"
                    label="Owner name"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    value={this.state.activeOwnerName}
                    onChange={(e) => {
                      this.setState({
                        activeOwnerName: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="activeVehicleNo"
                    label="Vehicle no"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    value={this.state.activeVehicleNo}
                    onChange={(e) =>
                      this.setState({
                        activeVehicleNo: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Col>
                <Col>
                  <TextField
                    id="activeMobile"
                    label="Mobile"
                    variant="outlined"
                    className="m-2"
                    defaultValue=""
                    value={this.state.activeMobile}
                    onChange={(e) =>
                      this.setState({
                        activeMobile: e.target.value,
                      })
                    }
                    fullWidth
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <div className="container-fluid border m-0 p-0 main">
        <div className="container-fluid border m-0 p-1">
          <div class="btn-group" role="group" aria-label="Basic example">
            <Button
              className="mt-1 mr-1 mb-3"
              color="secondary"
              variant="contained"
              onClick={(e) => {
                this.setState({ showAddModal: true });
              }}
            >
              add new vehicle
            </Button>
            <Button
              color="primary"
              variant="contained"
              className="mt-1 mr-1 mb-3 ml-5"
              onClick={this.refreshVehicles}
            >
              <FontAwesomeIcon icon={faSyncAlt} size="2x" />
            </Button>
          </div>

          {this.renderNewPartyModal()}
          {this.renderUpdatePartyModal()}

          <Row className="ml-0 mr-0">
            <Col md="12" className="p-0 m-0 measure1">
              {this.state.isLoadingVehicles && (
                <Box
                  width="100%"
                  height="100px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <CircularProgress />
                </Box>
              )}
              {!this.state.isLoadingVehicles && (
                <div>
                  <table
                    id="vehicles_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Party Id</th>
                        <th>Vehicle No</th>
                        <th>Owner Name</th>
                        <th>Mobile No</th>
                        <th>Options</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderVehiclesData()}</tbody>
                  </table>
                </div>
              )}
            </Col>
          </Row>
        </div>

        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
      </div>
    );
  }
}
