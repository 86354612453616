import React from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  HashRouter,
} from "react-router-dom";

// import CSS styles
import "./App.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";

// import components
import Login from "./components/user_authentication/Login";
import Main from "./components/main_dashboard/Main";

// import context
import { WorkerProvider } from "./contexts/workerContext";

function App() {
  return (
    <WorkerProvider>
      <HashRouter>
        <div className="container-fluid m-0 p-0">
          <Switch>
            {/* <Route path="/mainDashboard" exact component={Main} /> */}
            <Route path="/auth" exact component={Login} />
            <Route path="/" component={Main} />
          </Switch>
        </div>
      </HashRouter>
    </WorkerProvider>
  );
}

export default App;

// testing
