import React, { Component } from "react";

import {
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
// import { TabContext, TabList, TabPanel } from "@material-ui/lab";
// import { Row, Col, Card, Badge, Table as Tbl } from "react-bootstrap";
import "./style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";

//API handling components
import { API_URL } from "./../../global";
import { Autocomplete } from "@material-ui/lab";
const axios = require("axios");

export class AddNewEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplier: null,
      productId: "",
      quntity: 0,
      amount: 0,
      paid: 0,
      pending: 0,

      supplierList: [],

      products: [
        "preform_500_ml",
        "perform_1_lit",
        "preform_2_lit",
        "l_dawar_500_ml",
        "l_dawar_1_lit",
        "l_dawar_2_lit",
        "l_dawar_5_lit",
        "l_dawar_20_lit",
        "l_warana_1_lit",
        "green_cap",
        "blue_cap",
        "white_cap",
        "b_dawar_500_ml",
        "b_dawar_1_lit",
        "b_dawar_2_lit",
        "b_warana_1_lit",
        "can_5_lit",
        "jar_20_lit",
        "handle_2_lit",
        "cello_tape",
      ],
    };
  }

  fetchSupplierList() {
    let url = API_URL;
    // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    const query = `SELECT name FROM party where type=3;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("supplier data: ", res.data);
        this.setState({ supplierList: res.data.map((record) => record.name) });
      })
      .catch((err) => {
        console.log("id + name fetch error: ", err);
      });
  }

  updateProductCount() {
    const query = `UPDATE stockCount SET quantity = quantity + ${this.state.quantity} WHERE productId=${this.state.productId};`;

    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("Product count updated successfully");
        setTimeout(() => {
          this.props.refreshLedger();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();

    // calculate pending amount
    let pending = this.state.amount - this.state.paid;

    const query = `INSERT INTO stockLedger( supplier, productId, quantity, amount, paid, pending) VALUES('${this.state.supplier}', ${this.state.productId}, ${this.state.quantity}, ${this.state.amount}, ${this.state.paid}, ${pending});`;

    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(API_URL, data)
      .then((res) => {
        console.log("BTledger record added successfully");
        toast.success("Product Tracking record added successfully");
        this.updateProductCount();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.fetchSupplierList();
  }

  renderMenu() {
    return this.state.products.map((product, index) => {
      return (
        <MenuItem value={index + 1}>{product.replaceAll("_", " ")}</MenuItem>
      );
    });
  }

  render() {
    return (
      <div className="row">
        <form autoComplete="off">
          <div className="row ml-4 mt-4">
            <FormControl
              style={{ minWidth: "250px" }}
              className="mr-2 mb-2 smt-0"
            >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={this.state.supplierList}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // label="party name"
                    id="supplier"
                    label="Supplier"
                    variant="outlined"
                    type="text"
                    className="mr-2"
                    required={true}
                    size="small"
                  />
                )}
                onChange={(event, value) => {
                  this.setState({
                    supplier: value,
                  });
                }}
              />
            </FormControl>
            <FormControl
              // variant="filled"
              variant="outlined"
              className="mr-2 mb-2"
              style={{ minWidth: "150px" }}
              size="small"
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Product
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                onChange={(e) => {
                  this.setState({
                    productId: e.target.value,
                  });
                }}
                name="productId"
                value={this.state.productId}
                label="Product"
              >
                {this.renderMenu()}
              </Select>
            </FormControl>
            <TextField
              id="quantity"
              label="Quantity"
              variant="outlined"
              type="number"
              className="mr-2"
              size="small"
              style={{ width: 150 }}
              onChange={(e) => this.setState({ quantity: e.target.value })}
            />

            <TextField
              id="amount"
              label="amount"
              variant="outlined"
              type="number"
              className="mr-2"
              size="small"
              style={{ width: 150 }}
              onChange={(e) => this.setState({ amount: e.target.value })}
            />
            <TextField
              id="paid"
              label="paid"
              variant="outlined"
              className="mr-2"
              type="number"
              size="small"
              style={{ width: 150 }}
              onChange={(e) => this.setState({ paid: e.target.value })}
            />

            <Button
              color="primary"
              variant="contained"
              className=""
              className="mb-3"
              onClick={(e) => this.handleAddSubmit(e)}
            >
              <FontAwesomeIcon icon={faPlusCircle} size="2x" />
            </Button>
            <Button
              color="secondary"
              variant="contained"
              className="mb-3 ml-2"
              onClick={this.props.refreshLedger}
            >
              <FontAwesomeIcon icon={faSyncAlt} size="2x" />
            </Button>
          </div>
        </form>
        <ToastContainer />
      </div>
    );
  }
}
