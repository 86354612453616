import React, { useState } from "react";
import { Row, Col, Navbar } from "react-bootstrap";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  HashRouter,
} from "react-router-dom";
// import CSS styles
import "bootstrap/dist/css/bootstrap.css";

// import components
import NavbarPanel from "./NavbarPanel";
import PartyManager from "../party_manager/PartyManager";
import RouteManager from "../route_manager/RouteManager";
import ExpenseManager from "../expense_manager/ExpenseManager";
import StockManager from "../stock_manager/StockManager";
import OrderManager from "../order_manager/orderManager";
import LedgerManager from "../ledger_manager/LedgerManager";
import VehicleManager from "../vehicle_manager/VehicleManager";
import VehicleLedgerManager from "../vehicle_ledger_manager/VehicleLedgerManager";
import WorkerManager from "../worker_manager/WorkerManager";
import PresentyManager from "../presenty_manager/PresentyManager";

function Main(props) {
  // const [authenticated, setAuthenticated] = useState(props.location.state);
  const [auth, setAuth] = useState(JSON.parse(localStorage.getItem("auth")));

  function logout() {
    // setAuthenticated(false);
    setAuth(null);
    localStorage.setItem("auth", null);
  }
  if (auth && auth.isAuthenticated) {
    //const { userName } = authenticated;
    const userName = "user1";
    return (
      <HashRouter>
        <Navbar bg="dark" expand="lg">
          <Navbar.Brand id="title" href="#home">
            D. B. Foods & Beverages | Admin Panel
          </Navbar.Brand>
        </Navbar>
        <div className="container-fluid m-0 p-0">
          <Row className="m-0 p-0">
            <Col className="col-sm-2 mt-1 pl-1 pr-1">
              <NavbarPanel user={userName} logout={() => logout()} />
            </Col>
            <Col className="col-sm-10 mt-1 p-0">
              <Switch>
                {/* Manager Routes */}
                <Route exact path="/mainDashboard" component={OrderManager} />
                <Route path="/partyManager" exact component={PartyManager} />
                <Route path="/RouteManager" exact component={RouteManager} />
                <Route path="/stockManager" exact component={StockManager} />
                <Route
                  path="/expenseManager"
                  exact
                  component={ExpenseManager}
                />
                <Route
                  path="/ledgerManager/:partyId"
                  exact
                  component={LedgerManager}
                />
                <Route
                  path="/vehicleManager/"
                  exact
                  component={VehicleManager}
                />
                <Route
                  path="/vehicleLedgerManager/:vehicleId"
                  exact
                  component={VehicleLedgerManager}
                />
                <Route path="/workerManager" exact component={WorkerManager} />
                <Route
                  path="/presentyManager/:workerId"
                  exact
                  component={PresentyManager}
                />
              </Switch>
            </Col>
          </Row>
        </div>
      </HashRouter>
    );
  } else {
    return <Redirect to="/auth" />;
  }
}

export default Main;
